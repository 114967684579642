.container {
  background-color: #f8f8f8;
  min-height: 100vh;
  font-family: PingFangSC-Regular;
}
.user_msg {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 8px;
}
.user_msg .content-top {
  margin: 0 0.2rem;
}
.user_msg .content-top .all_cout {
  display: flex;
  padding: 0.24rem 0;
}
.user_msg .content-top .all_cout .con_left {
  color: #5899f6;
  font-size: 0.3rem;
  font-weight: 500;
}
.user_msg .content-top .all_cout .con_right {
  color: #5899f6;
  font-size: 0.3rem;
  font-weight: 500;
  margin-left: 0.28rem;
}
.user_msg .content {
  margin: 0 0.2rem;
  padding: 0.1rem 0 0.3rem 0;
}
.user_msg .content .list {
  display: flex;
  align-items: center;
  padding: 0.15rem 0;
}
.user_msg .content .list .con_left {
  font-family: PingFangSC-Medium;
  width: 24%;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 550;
  text-align: left;
}
.user_msg .content .list .con_left1 {
  font-family: PingFangSC-Medium;
  color: #5899f6;
  font-size: 0.24rem;
  font-weight: 500;
  text-align: left;
}
.user_msg .content .list .con_right {
  width: 76%;
  color: #6F7A8B;
  font-size: 0.28rem;
  margin-left: 0.2rem;
}
.user_msg .content .list .con_right1 {
  color: #5899f6;
  font-size: 0.24rem;
}
.user_msg .content .list .right-jingxin {
  color: #13ad8f;
}
.user_msg .content .list .right-shenhe {
  color: #d98181;
}
.user_msg .content .list0 {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.01rem solid rgba(211, 217, 222, 0.5);
  align-items: center;
  padding: 0.2rem 0;
  margin-bottom: 0.15rem;
}
.user_msg .content .list0 .con_left {
  font-family: PingFangSC-Medium;
  color: #333333;
  font-size: 0.28rem;
  font-weight: bold;
  text-align: left;
}
.user_msg .content .list0 .con_right {
  color: #666666;
  font-size: 0.24rem;
  margin-left: 0.28rem;
}
.user_msg .content .list0 .right-jingxin {
  color: #32BF88;
}
.user_msg .content .list0 .right-shenhe {
  color: #d98181;
}
.user_msg .content .list3 {
  padding: 0.2rem 0;
}
.user_msg .content .list3 .con_left {
  font-family: PingFangSC-Medium;
  color: #333333;
  font-size: 0.3rem;
  font-weight: bold;
  text-align: left;
}
.user_msg .content .list4 {
  display: flex;
  align-items: center;
  padding-bottom: 0.2rem;
  border-bottom: 0.01rem solid rgba(211, 217, 222, 0.5);
}
.user_msg .content .list4 .con_left1 {
  font-family: PingFangSC-Medium;
  color: #6F7A8B;
  font-size: 0.24rem;
  font-weight: 500;
  text-align: left;
}
.user_msg .content .list4 .con_right1 {
  color: #6F7A8B;
  font-size: 0.24rem;
}
.user_msg .content .list5 {
  display: flex;
  align-items: center;
  padding: 0.24rem 0;
  justify-content: space-between;
}
.user_msg .content .list5 .dq {
  width: 50%;
  color: #6F7A8B;
  font-size: 0.24rem;
  font-weight: 500;
  text-align: center;
}
.user_msg .content .list5 .ls {
  width: 50%;
  margin-left: 0.46rem;
  color: #6F7A8B;
  font-size: 0.24rem;
  font-weight: 500;
  text-align: center;
}
.user_msg .content .list5 .active {
  font-family: PingFangSC-Medium;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 550;
}
.user_msg .content .list6 {
  margin-top: 0.46rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.28rem;
  border-bottom: 0.01rem solid rgba(211, 217, 222, 0.5);
}
.user_msg .content .list6 .left_user {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user_msg .content .list6 .left_user .img {
  width: 1.2rem;
  height: 1.2rem;
}
.user_msg .content .list6 .left_user .img img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.user_msg .content .list6 .left_user .name {
  margin-top: 0.08rem;
  color: #333333;
  font-weight: 500;
  font-size: 0.28rem;
}
.user_msg .content .list6 .msg-center {
  margin-top: 0.07rem;
}
.user_msg .content .list6 .msg-center .msg-item {
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user_msg .content .list6 .msg-center .msg-item .mag-left {
  color: #333333;
  font-size: 0.28rem;
  font-weight: 500;
  text-align: left;
}
.user_msg .content .list6 .msg-center .msg-item .msg-right {
  margin-left: 0.2rem;
  color: #6F7A8B;
  font-size: 0.28rem;
}
.user_msg .content .list6 .msg-center .msg-item:first-child {
  margin-top: 0;
}
.user_msg .content .list6 .msg-center .msg-item:last-child {
  margin-top: 0.18rem;
}
.user_msg .content .list6 .msg-last {
  margin-top: 0.95rem;
}
.user_msg .content .list6 .msg-last .msg-right-top {
  text-align: right;
  font-size: 0.24rem;
  font-weight: bold;
  color: #32BF88;
}
.user_msg .content .list6 .msg-last .msg-right-bottom {
  text-align: center;
  font-size: 0.24rem;
  font-weight: bold;
  color: #32BF88;
}
.user_msg .content .list6:last-child {
  border-bottom: none;
}
.user_msg .content .button {
  margin: 0 auto;
  margin-top: 0.3rem;
  width: 6.7rem;
  height: 0.8rem;
  color: #2E62FF;
  line-height: 0.8rem;
  border-radius: 0.08rem;
  text-align: center;
  font-size: 0.32rem;
  border: 0.01rem solid #2E62FF;
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 0;
  width: 100%;
}
.bottom .button {
  margin: 0.19rem 0.2rem;
  padding: 0.22rem 2.82rem;
  background: linear-gradient(131deg, #527EFF 0%, #5569FC 100%);
  color: #FFFFFF;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.08rem;
  font-weight: 500;
}
.tan {
  width: 5.6rem;
  height: 2.94rem;
  position: relative;
}
.tan .tan_no_txt1 {
  padding-top: 0.69rem;
  padding-left: 0.2rem;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
  color: #333333;
}
.tan .tan_no_txt2 {
  margin-top: 0.05rem;
  color: #333333;
  font-size: 0.24rem;
  color: #666666;
  text-align: center;
}
.tan .tan_no_txt2 .right {
  color: #2E62FF;
}
.tan .tan_no_button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border-top: 0.01rem solid #D3D9DE;
}
.tan .tan_no_button .yes {
  flex: 1;
  color: #2E62FF;
  font-size: 0.28rem;
  border-right: 0.01rem solid #D3D9DE;
}
.tan .tan_no_button .no {
  flex: 1;
  color: #2E62FF;
}
